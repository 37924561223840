import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"
import { Link } from "gatsby"
import Img from "gatsby-image"

const base = css`
  position: relative;

  @media (min-width: 1280px) {
    border: 8px solid ${colors.white};
    display: grid;

    grid-gap: 0;

    ::before,
    ::after {
      background-color: ${colors.white};
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
    }

    ::before {
      left: 50%;
      top: 0;
      width: 8px;
      height: 100%;
      transform: translateX(-50%);
    }

    ::after {
      left: 0;
      top: 50%;
      width: 100%;
      height: 8px;
      transform: translateY(-50%);
    }
  }
`

export const BlogGridWrapper = styled.section`
  ${base}
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
`

export const BlogGrid2xWrapper = styled.section`
  ${base}
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
`

export const BlogGalleryControls = styled.div`
  position: absolute;
  bottom: 4rem;
  left: 0;
  right: 0;
  width: calc(100% - 8rem);
  height: 8rem;
  margin: 0 4rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${colors.white};
  z-index: 2;

  p {
    color: ${colors.darkolive};
    ${fonts.play};
    font-size: 1.5rem;
    text-align: center;
    max-width: 75%;
    margin: 0 auto;
  }

  svg {
    color: ${colors.darkolive};
    ${transitions.default};

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
`

export const BlogGridSlide = styled(Img)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  left: 0;
  top: 0;
`

const galleryStyles = {
  position: "relative",
  overflow: "hidden",
  margin: 0,
  padding: 0,

  "@media(max-width: 1279px)": {
    display: "none",
  },
}

export const BlogGridGallery = styled.div`
  grid-area: 1 / 1 / 3 / 3;
  ${galleryStyles};
`

export const BlogGridGallery2 = styled.div`
  grid-area: 3 / 3 / 5 / 5;
  ${galleryStyles};
`

export const BlogPostTitle = styled.p`
  ${fonts.play};
  color: ${colors.darkolive};
  font-size: 1.75rem;
  margin: 0;
  text-transform: capitalize;
`
export const BlogPostContent = styled.div`
  ${fonts.mont};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 2;
  margin-top: 2rem;
  height: 300px;
  overflow: hidden;
`

export const BlogPostLink = styled(Link)`
  padding: 1.5rem 3rem;
  ${fonts.mont.bold};
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: ${colors.white};
  background-color: ${colors.black};
  display: block;
  text-decoration: none;
  text-align: center;
  margin: 2rem 0;
  ${transitions.default};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.darkolive};
  }
`

export const BlogPostDate = styled.p`
  ${fonts.lato.black};
  color: ${colors.darkolive};
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 3px;
  margin-bottom: 0;
`

export const BlogGridPostLeft = styled.div`
  padding: 2rem;
  background-color: ${colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
`

export const BlogGridPostRight = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  & > div {
    height: 100%;
  }
`

const post = css`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1279px) {
    min-height: 450px;
  }
`

export const BlogGridPost = styled.article`
  ${post}

  :first-of-type {
    ${BlogGridPostLeft} {
      @media (max-width: 1279px) {
        padding-top: 8rem;
      }
    }
  }

  &:nth-of-type(even) {
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }

    ${BlogGridPostLeft} {
      &::before {
        left: 0;
        top: 2rem;
        transform: translateX(-50%) rotate(45deg);
      }
    }
  }

  &:nth-of-type(odd) {
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    ${BlogGridPostLeft} {
      &::before {
        right: 0;
        top: 2rem;
        transform: translateX(50%) rotate(45deg);
      }
    }
  }

  &:nth-of-type(1) {
    grid-area: 1 / 3 / 2 / 5;
  }

  &:nth-of-type(2) {
    grid-area: 2 / 3 / 3 / 5;
  }

  &:nth-of-type(3) {
    grid-area: 3 / 1 / 4 / 3;
  }

  &:nth-of-type(4) {
    grid-area: 4 / 1 / 5 / 3;
  }
`

export const BlogGrid2xPost = styled.article`
  ${post}
`
