import React from "react"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import PortableText from "@sanity/block-content-to-react"
import Img from "gatsby-image"

import {
  PostHero,
  PostTitle,
  PostContent,
  PostContentLeft,
  PostContentLeftBody,
  PostContentDate,
  PostContentRight,
  PostNextTitle,
} from "./post-styles"

import {
  BlogGridPost,
  BlogGridPostLeft,
  BlogPostTitle,
  BlogPostContent,
  BlogPostLink,
  BlogPostDate,
  BlogGridPostRight,
} from "../../components/blog/blog-grid-styles"

const SinglePost = ({ data, pageContext }) => {
  const title = pageContext.title
  const content = data.current._rawContent
  const date = data.current.publishedAt
  const featuredImg = data.current.featuredImage.image.asset.fluid

  return (
    <>
      <SEO title={title} />
      <PostHero>
        <PostTitle>{title}</PostTitle>
      </PostHero>
      <PostContent>
        <PostContentLeft>
          <PostContentDate>
            {new Date(date)
              .toDateString()
              .split(" ")
              .slice(1)
              .join(" ")}
          </PostContentDate>
          <PostContentLeftBody>
            <PortableText
              blocks={content}
              serializers={{
                types: {
                  ptImage: ({ node }) => (
                    <img src={node.image.asset.url} alt={node.alt} />
                  ),
                },
              }}
            />
          </PostContentLeftBody>
          {data.next && (
            <>
              <PostNextTitle>Next Post</PostNextTitle>
              <BlogGridPost>
                <BlogGridPostLeft>
                  <BlogPostTitle>{data.next.title}</BlogPostTitle>
                  <BlogPostContent>
                    <PortableText
                      blocks={data.next._rawContent}
                      serializers={{
                        types: {
                          ptImage: ({ node }) => (
                            <img src={node.image.asset.url} alt={node.alt} />
                          ),
                        },
                      }}
                    />
                  </BlogPostContent>
                  <BlogPostLink to={"/blog/" + data.next.slug.current + "/"}>
                    Read More
                  </BlogPostLink>
                  <BlogPostDate>
                    {new Date(data.next.publishedAt)
                      .toDateString()
                      .split(" ")
                      .slice(1)
                      .join(" ")}
                  </BlogPostDate>
                </BlogGridPostLeft>
                <BlogGridPostRight>
                  <Img fluid={data.next.featuredImage.image.asset.fluid} />
                </BlogGridPostRight>
              </BlogGridPost>
            </>
          )}
        </PostContentLeft>
        <PostContentRight>
          <Img fluid={featuredImg} />
        </PostContentRight>
      </PostContent>
    </>
  )
}

export const query = graphql`
  query postData($slug: String, $next: String) {
    current: sanityPost(slug: { current: { eq: $slug } }) {
      title
      publishedAt
      featuredImage {
        image {
          asset {
            fluid(maxWidth: 960) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
    next: sanityPost(slug: { current: { eq: $next } }) {
      title
      slug {
        current
      }
      publishedAt
      featuredImage {
        image {
          asset {
            fluid(maxWidth: 960) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default SinglePost
